import z from 'zod';
import {
  AnalyticsDomain,
  AnalyticsBenchmarkValue,
  AnalyticsValueType,
  BenchmarkFilter,
  StatisticType,
  DateLevel,
  FipsAreaType,
  AnalyticsSubdomain,
  ActivationState,
  VisualizationType,
  VariableDirection,
  VariableDemographicSegment,
} from './enums';
import { zodDate } from '../utils';
import {
  Brand,
  MapExtentBounds,
  OverviewAnalyticsDomain,
  SafeRecordDictionary,
  YEAR_FORMAT,
} from 'core';
import _ from 'lodash';
import moment from 'moment';
import { MultiPolygon } from '@turf/helpers';

export * from './enums';

export const trackFilterQueryParameters = [
  'filter',
  'comparison_group_id',
  'display_type',
  'area_mode',
];

export const NationalDataFips = '0';

export const NationalDataFipsArea: FipsAreaWithShortName = {
  id: NationalDataFips,
  type: FipsAreaType.NATION,
  name: 'United States',
  shortName: 'United States',
};

export type AnalyticsDomainType = z.infer<typeof AnalyticsDomainTypeSchema>;
const AnalyticsDomainTypeSchema = z.object({
  domain: z.nativeEnum(AnalyticsDomain),
  subdomain: z.nativeEnum(AnalyticsSubdomain).nullable(),
});

export type AnalyticsVariable = z.infer<typeof AnalyticsVariableSchema>;
export const AnalyticsVariableSchema = z.object({
  id: z.string(),
  name: z.string(),
  domains: z.array(AnalyticsDomainTypeSchema).readonly(),
  statisticType: z.nativeEnum(StatisticType),
  valueType: z.nativeEnum(AnalyticsValueType),
  suffix: z.string().nullable(),
  label: z.string(),
  description: z.string().nullable(),
  source: z.string().nullable(),
  dateLevel: z.nativeEnum(DateLevel),
  includedInIndex: z.boolean(),
  direction: z.nativeEnum(VariableDirection).nullable(),
  isOA: z.boolean(),
  isDefault: z.boolean(),
  demographicSegment: z.nativeEnum(VariableDemographicSegment),
});

const AnalyticsBenchmarkSchema = z.object({
  filter: z.nativeEnum(BenchmarkFilter),
  value: z.nativeEnum(AnalyticsBenchmarkValue),
  percentileBucketIndex: z.number().nullable(),
});

export type FipsArea = z.infer<typeof FipsAreaSchema>;
export const FipsAreaSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(FipsAreaType).nullable(),
});

export const FipsAreaWithShortNameSchema = FipsAreaSchema.and(
  z.object({
    shortName: z.string().nullable(),
  })
);
export type FipsAreaWithShortName = z.infer<typeof FipsAreaWithShortNameSchema>;

export type TrackDatum = z.infer<typeof TrackDatumSchema>;
export const TrackDatumSchema = z.object({
  id: z.string(),
  fipsArea: FipsAreaWithShortNameSchema,
  value: z.number(),
  recordedAt: zodDate,
  benchmarkValues: z.array(AnalyticsBenchmarkSchema).readonly(),
  previousValue: z
    .object({
      id: z.string(),
      value: z.number(),
      recordedAt: zodDate,
    })
    .optional(),
});

export type TrackDatumResult = z.infer<typeof TrackDatumResultSchema>;
export const TrackDatumResultSchema = z.object({
  id: z.string(),
  fipsArea: FipsAreaSchema,
  value: z.number(),
  recordedAt: zodDate,
  benchmarkValues: z.array(AnalyticsBenchmarkSchema).readonly(),
  previousValue: z
    .object({
      id: z.string(),
      value: z.number(),
      recordedAt: zodDate,
    })
    .optional(),
});
export const PredictiveDatumSchema = TrackDatumResultSchema.omit({
  previousValue: true,
  benchmarkValues: true,
});
export type PredictiveDatumResult = z.infer<typeof PredictiveDatumSchema>;
export type TrackAreaData = z.infer<typeof TrackAreaDataSchema>;
export const TrackAreaDataSchema = z.object({
  fipsArea: FipsAreaWithShortNameSchema,
  performanceData: z.array(TrackDatumSchema),
  arPredictiveData: z.array(PredictiveDatumSchema),
  gpalPredictiveData: z.array(PredictiveDatumSchema),
});
export const TrackAreaDataResultSchema = z.object({
  fipsArea: FipsAreaSchema,
  performanceData: z.array(TrackDatumResultSchema),
  arPredictiveData: z.array(PredictiveDatumSchema),
  gpalPredictiveData: z.array(PredictiveDatumSchema),
});
export type TrackVariable = z.infer<typeof TrackVariableSchema>;
export const TrackVariableSchema = z.object({
  id: z.string(),
  name: z.string(),
  domains: z.array(AnalyticsDomainTypeSchema).readonly(),
  statisticType: z.nativeEnum(StatisticType),
  valueType: z.nativeEnum(AnalyticsValueType),
  suffix: z.string().nullable(),
  label: z.string(),
  description: z.string().nullable(),
  source: z.string().nullable(),
  dateLevel: z.nativeEnum(DateLevel),
  includedInIndex: z.boolean(),
  direction: z.nativeEnum(VariableDirection).nullable(),
  isOA: z.boolean(),
  isDefault: z.boolean(),
  demographicSegment: z.nativeEnum(VariableDemographicSegment),
  areasData: z.array(TrackAreaDataSchema),
});

export type TrackVariableMetadata = Omit<TrackVariable, 'areasData'>;

export type TrackVariableResult = z.infer<typeof TrackVariableResultSchema>;
export const TrackVariableResultSchema = z.object({
  id: z.string(),
  name: z.string(),
  domains: z.array(AnalyticsDomainTypeSchema).readonly(),
  statisticType: z.nativeEnum(StatisticType),
  valueType: z.nativeEnum(AnalyticsValueType),
  suffix: z.string().nullable(),
  label: z.string(),
  description: z.string().nullable(),
  source: z.string().nullable(),
  dateLevel: z.nativeEnum(DateLevel),
  includedInIndex: z.boolean(),
  direction: z.nativeEnum(VariableDirection).nullable(),
  isOA: z.boolean(),
  isDefault: z.boolean(),
  demographicSegment: z.nativeEnum(VariableDemographicSegment),
  areasData: z.array(TrackAreaDataResultSchema),
});

export interface TrackDomain {
  readonly id: string;
  readonly indexScores: readonly TrackVariableWithDistributions[];
  readonly indicators: readonly TrackVariable[];
  readonly sentimentValues: readonly TrackVariable[];
}

export type DistributionBin = z.infer<typeof DistributionBinSchema>;
export const DistributionBinSchema = z.object({
  id: z.string(),
  percentile: z.number(),
  percentValue: z.number(),
  count: z.number().nullable(),
});

export type PerformanceDataDistribution = z.infer<
  typeof PerformanceDataDistributionSchema
>;
export const PerformanceDataDistributionSchema = z.object({
  distributionId: z.string(),
  variableId: z.string(),
  performanceDataId: z.string(),
  filter: z.nativeEnum(BenchmarkFilter),
  comparisonGroupId: z.string().nullable(),
  geoType: z.nativeEnum(FipsAreaType),
  numBins: z.number(),
  bins: z.array(DistributionBinSchema).readonly(),
});

export type DistributionCoreData = z.infer<typeof DistributionCoreDataSchema>;
export const DistributionCoreDataSchema = PerformanceDataDistributionSchema.pick({
  distributionId: true,
  bins: true,
  filter: true,
  comparisonGroupId: true,
  geoType: true,
});

const SavedVisualizationSchema = z.object({
  id: z.string(),
  benchmarkFilter: z.nativeEnum(BenchmarkFilter),
  fips: z.string().nullable(),
  recordedAt: zodDate.nullable(),
  publishingEntityId: z.string(),
  state: z.nativeEnum(ActivationState),
  visualizationType: z.nativeEnum(VisualizationType).nullable(),
  performanceData: z.array(TrackDatumSchema),
});

export const SavedVisualizationsWithTrackVariablesSchema =
  SavedVisualizationSchema.omit({ performanceData: true, fips: true }).extend({
    baseFips: z.string().nullable(),
    trackVariables: z.array(TrackVariableResultSchema),
    comparisonGroupId: z.string().optional(),
  });
export const CreateVisualizationInputSchema = SavedVisualizationSchema.omit({
  id: true,
  publishingEntityId: true,
  performanceData: true,
}).extend({
  variableId: z.string(),
});

export const CreateVisualizationInputWithMultipleFipsSchema =
  SavedVisualizationsWithTrackVariablesSchema.omit({
    id: true,
    publishingEntityId: true,
    trackVariables: true,
  }).extend({
    groupFips: z.array(z.string()).readonly(),
    comparisonGroupId: z.string().nullable(),
    variableId: z.string(),
  });

export type SavedVisualization = z.infer<typeof SavedVisualizationSchema>;
export type SavedVisualizationWithTrackVariables = z.infer<
  typeof SavedVisualizationsWithTrackVariablesSchema
>;
export type CreateVisualizationInput = z.infer<
  typeof CreateVisualizationInputSchema
>;
export type CreateVisualizationInputWithMultipleFips = z.infer<
  typeof CreateVisualizationInputWithMultipleFipsSchema
>;

export const TrackDatumWithDistributionsSchema = TrackDatumSchema.and(
  z.object({
    distributions: z.array(DistributionCoreDataSchema).readonly(),
  })
);

export type TrackDatumWithDistributions = z.infer<
  typeof TrackDatumWithDistributionsSchema
>;

export type TrackVariableWithDistributions = z.infer<
  typeof TrackVariableWithDistributionsSchema
>;
export const TrackVariableWithDistributionsSchema = z.object({
  id: z.string(),
  name: z.string(),
  domains: z.array(AnalyticsDomainTypeSchema).readonly(),
  statisticType: z.nativeEnum(StatisticType),
  valueType: z.nativeEnum(AnalyticsValueType),
  suffix: z.string().nullable(),
  label: z.string(),
  description: z.string().nullable(),
  source: z.string().nullable(),
  dateLevel: z.nativeEnum(DateLevel),
  includedInIndex: z.boolean(),
  direction: z.nativeEnum(VariableDirection).nullable(),
  isOA: z.boolean(),
  isDefault: z.boolean(),
  demographicSegment: z.nativeEnum(VariableDemographicSegment),
  areasData: z.array(
    z.object({
      fipsArea: FipsAreaWithShortNameSchema,
      performanceData: z.array(TrackDatumWithDistributionsSchema),
      arPredictiveData: z.array(PredictiveDatumSchema),
      gpalPredictiveData: z.array(PredictiveDatumSchema),
    })
  ),
});

export const ClientSavedVisualizationSchema =
  SavedVisualizationsWithTrackVariablesSchema.omit({
    trackVariables: true,
  }).extend({
    trackVariables: z.array(TrackVariableResultSchema),
  });

export type ClientVisualizationId = Brand<string, 'Visualization'>;

export type ClientSavedVisualization = Omit<
  z.infer<typeof ClientSavedVisualizationSchema>,
  'trackVariables' | 'id'
> & {
  readonly id: ClientVisualizationId;
  readonly title: string;
  readonly trackVariables: readonly TrackVariableWithDistributions[];
  readonly placeShapeByFips: SafeRecordDictionary<string, MultiPolygon>;
  readonly mapBoundCoordinates: MapExtentBounds | null;
};

export type TrackDomainsData = {
  readonly [key in OverviewAnalyticsDomain]: TrackDomain;
};

export function datumSortDesc<T extends { readonly recordedAt: Date }>(
  a: T,
  b: T
): number {
  return b.recordedAt.getTime() - a.recordedAt.getTime();
}

export function getMostRecentDatumCurrentPub<
  K extends TrackDatum,
  T extends {
    readonly areasData: readonly {
      readonly fipsArea: FipsArea;
      readonly performanceData: readonly K[];
    }[];
  },
>(currentFipsArea: FipsArea, variable?: T): K | undefined {
  return _.orderBy(
    variable?.areasData.find((ad) => ad.fipsArea.id === currentFipsArea.id)
      ?.performanceData,
    'recordedAt',
    'desc'
  )[0];
}

const getYearFromDate = (recordedAt: Date) =>
  Number(moment.utc(recordedAt).format(YEAR_FORMAT));

export function getDatumCurrentPubForYear<
  K extends TrackDatum,
  T extends {
    readonly areasData: readonly {
      readonly fipsArea: FipsArea;
      readonly performanceData: readonly K[];
    }[];
  },
>(currentFipsArea: FipsArea, year: number, variable?: T): K | undefined {
  return variable?.areasData
    .find((ad) => ad.fipsArea.id === currentFipsArea.id)
    ?.performanceData.find((pd) => getYearFromDate(pd.recordedAt) === year);
}
