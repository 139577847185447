import * as ApolloC from '@apollo/client/react/components';
import * as Apollo from '@apollo/client/react';
import * as React from 'react';
import { DocumentNode } from 'graphql';
import {
  FetchResult,
  MutationUpdaterFn,
  PureQueryOptions,
  RefetchQueriesFunction,
  OperationVariables,
} from '@apollo/client';

export type MutationFunctionOptionsReadonly<TData, TVariables> = Omit<
  Apollo.MutationHookOptions<TData, TVariables>,
  'refetchQueries'
> & {
  readonly refetchQueries?:
    | readonly (string | PureQueryOptions)[]
    | RefetchQueriesFunction;
};

// NOTE: if we determine some options are often the same when a mutation is used, we could specify
// defaults here
export interface MutationInfo<TData, TVariables> {
  readonly document: DocumentNode;
  readonly defaultUpdater?: MutationUpdaterFn<TData>;
  readonly _vars?: TVariables; // never set
  readonly _data?: TData; // never set
}

export type MutationProps<TData, TVariables> = Omit<
  ApolloC.MutationComponentOptions<TData, TVariables>,
  'mutation' | 'children'
> & {
  readonly mutation: MutationInfo<TData, TVariables>;
  readonly children: (
    mutateFn: Apollo.MutationFunction<TData, TVariables>,
    result: Apollo.MutationResult<TData>,
    lastCalled: TVariables | null
  ) => JSX.Element | null;
};

export type ToMutationFn<T extends MutationInfo<any, any>> = Apollo.MutationFunction<
  NonNullable<T['_data']>,
  NonNullable<T['_vars']>
>;

export type ToMutationResult<T extends MutationInfo<any, any>> =
  Apollo.MutationResult<NonNullable<T['_data']>>;

export function mutationInfo<TData, TVariables>(a: {
  readonly document: DocumentNode;
  readonly defaultUpdater?: MutationUpdaterFn<TData>;
}): MutationInfo<TData, TVariables> {
  return a;
}

export declare type MutationFunctionReadonly<
  TData = any,
  TVariables = OperationVariables,
> = (
  options?: MutationFunctionOptionsReadonly<TData, TVariables>
) => Promise<FetchResult<TData>>;
export interface MutationReturn<TData, TVariables> {
  readonly fn: MutationFunctionReadonly<TData, TVariables>;
  readonly result: Apollo.MutationResult<TData>;
  readonly lastCalled: TVariables | null;
}

export type ToMutationReturn<T extends MutationInfo<any, any>> = MutationReturn<
  NonNullable<T['_data']>,
  NonNullable<T['_vars']>
>;

export function useMutationInfo<TData, TVariables>(
  mi: MutationInfo<TData, TVariables>,
  options?: MutationFunctionOptionsReadonly<TData, TVariables>
): MutationReturn<TData, TVariables> {
  const [lastCalled, setLastCalled] = React.useState<TVariables | null>(null);
  const [origFn, origResult] = Apollo.useMutation(
    mi.document,
    options as Apollo.MutationHookOptions<TData, TVariables>
  );

  const fn: MutationFunctionReadonly<TData, TVariables> = React.useCallback(
    (args) => {
      setLastCalled(args ? args.variables || null : null);
      return origFn(args as Apollo.MutationFunctionOptions<TData, TVariables>);
    },
    [origFn, setLastCalled]
  );
  return {
    fn,
    result: origResult,
    lastCalled,
  };
}
