import { Maybe } from '../helpers';
import { QuestionSetStatus } from './permissions';

export enum QuestionSetType {
  SET = 'SET',
  SURVEY = 'SURVEY',
  POLCO_LIVE = 'POLCO_LIVE',
  CONTENT_POST = 'CONTENT_POST',
}

export function questionSetTypeToCopy(type: QuestionSetType) {
  switch (type) {
    case QuestionSetType.SET:
      return 'Poll Set';
    case QuestionSetType.SURVEY:
      return 'Survey';
    case QuestionSetType.POLCO_LIVE:
      return 'Live Event';
    case QuestionSetType.CONTENT_POST:
      return 'Post';
  }
}

export enum RespondentsSetStatus {
  COMPLETE = 'COMPLETE',
  CLOSED = 'CLOSED',
  START = 'START',
  NOT_STARTED = 'NOT_STARTED',
}

export enum BenchmarkSurveyType {
  ARPA_BUSINESS = 'ARPA_BUSINESS',
  ARPA_RESIDENT = 'ARPA_RESIDENT',
  CASOA = 'CASOA',
  NBS = 'NBS',
  NCS = 'NCS',
  NCS_OA = 'NCS_OA',
  NES = 'NES',
  NLES = 'NLES',
  NPSS = 'NPSS',
  NES_LE = 'NES_LE',
  NCI = 'NCI',
}

// The Benchmark Survey types we expect to be able to fully support on-platform
export const SupportedBenchmarkSurveys = [
  BenchmarkSurveyType.ARPA_BUSINESS,
  BenchmarkSurveyType.ARPA_RESIDENT,
  BenchmarkSurveyType.CASOA,
] as const;

export type SupportedBenchmarkSurveyType =
  (typeof SupportedBenchmarkSurveys)[number];

export function isSupportedBenchmarkType(
  benchmarkType: BenchmarkSurveyType | null
): benchmarkType is SupportedBenchmarkSurveyType {
  switch (benchmarkType) {
    case BenchmarkSurveyType.ARPA_BUSINESS:
    case BenchmarkSurveyType.ARPA_RESIDENT:
    case BenchmarkSurveyType.CASOA:
      return true;
    case BenchmarkSurveyType.NBS:
    case BenchmarkSurveyType.NCS:
    case BenchmarkSurveyType.NCS_OA:
    case BenchmarkSurveyType.NES:
    case BenchmarkSurveyType.NLES:
    case BenchmarkSurveyType.NES_LE:
    case BenchmarkSurveyType.NPSS:
    case BenchmarkSurveyType.NCI:
    case null:
      return false;
  }
}

export function csvReportEnabled(
  benchmarkType: BenchmarkSurveyType | null
): boolean {
  return (
    !benchmarkType ||
    (benchmarkType !== BenchmarkSurveyType.NES &&
      benchmarkType !== BenchmarkSurveyType.NES_LE)
  );
}

/**
 * Use the question set status to determine if the content is closed.
 *
 * @param {QuestionSetStatus} questionSetStatus to be evaluated
 * @returns true if the status is in the closed values, otherwise false.
 */
export function isContentClosed(questionSetStatus: QuestionSetStatus) {
  return [
    QuestionSetStatus.CLOSED,
    QuestionSetStatus.HISTORIC_RECORD,
    QuestionSetStatus.ARCHIVED,
  ].includes(questionSetStatus);
}

/**
 * Evaluate the rules to decide if a content should use aggregates values or RQC.
 *
 * HasAutomaticWeighting = True we show aggregate results for non-benchmark survey
 * HasAutomaticWeighting = False we show RQC results for non-benchmark survey
 *
 * @returns true if pass all rules to use aggregates results, otherwise false.
 */
export function shouldUseAggregateResults({
  benchmarkType,
  hasAutomaticWeighting,
  questionSetStatus,
}: {
  readonly benchmarkType?: Maybe<BenchmarkSurveyType>;
  readonly hasAutomaticWeighting: boolean;
  readonly questionSetStatus: QuestionSetStatus;
}): boolean {
  const isBenchmarkSurvey = isSupportedBenchmarkType(benchmarkType ?? null);
  const isBenchmarkSurveyClosed =
    isBenchmarkSurvey && isContentClosed(questionSetStatus);
  const useAggregateResults = hasAutomaticWeighting || isBenchmarkSurveyClosed;
  return useAggregateResults;
}

export interface BenchmarkType {
  readonly type: BenchmarkSurveyType | null;
  readonly weighted: boolean;
}

export interface ScaleData {
  readonly scaleType: ScaleType;
  readonly scaleThreshold: number;
  readonly alternateScaleName: string | null;
}

export enum ScaleType {
  POSITIVE = 'POSITIVE',
  PROBLEMATIC = 'PROBLEMATIC',
}

export const DEFAULT_POSITIVE_NAME = 'positive';
export const DEFAULT_PROBLEMATIC_NAME = 'problematic';

export function scaleDataToName(scaleData: ScaleData) {
  if (scaleData.alternateScaleName) {
    return `${scaleData.alternateScaleName.toLowerCase()}`;
  }
  switch (scaleData.scaleType) {
    case ScaleType.POSITIVE:
      return DEFAULT_POSITIVE_NAME;
    case ScaleType.PROBLEMATIC:
      return DEFAULT_PROBLEMATIC_NAME;
  }
}

export enum ContentPostBlockType {
  CONTENT = 'CONTENT',
  QUESTION = 'QUESTION',
  DATA_POINT = 'DATA_POINT',
  BUDGET = 'BUDGET',
  HOUSING = 'HOUSING',
  PRIORITIZE = 'PRIORITIZE',
  RECEIPT = 'RECEIPT',
}
