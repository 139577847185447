import * as React from 'react';
import './styles.scss';
import classNames from 'classnames';
import { Pill, Props as PillProps } from '../pill';
import uuid from 'uuid';

export enum ExpandMode {
  EXPANDS = 'EXPANDS',
  ALWAYS_COLLAPSED = 'ALWAYS_COLLAPSED',
  ALWAYS_EXPANDED = 'ALWAYS_EXPANDED',
}

export enum ExpandDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface Props extends PillProps {
  readonly expandMode: ExpandMode;
  readonly expandedText: string;
  readonly expandDirection?: ExpandDirection;
  readonly expandedWidth?: number;
  readonly expandedClassName?: string;
  readonly pillClass?: string;
}

const baseClass = 'pn-expanding-pill';

const ExpandingPillContent: React.FC<Props> = (props) => {
  // one value and just keep it on subsequent renders,
  // but no harm in making unique and managing here
  const [tooltipId] = React.useState(uuid.v4);
  const useTooltipId =
    props.expandMode === ExpandMode.EXPANDS ? tooltipId : undefined;
  return (
    <span aria-describedby={useTooltipId} className={`${baseClass}-content`}>
      {props.children}
      {props.expandMode !== ExpandMode.ALWAYS_COLLAPSED && (
        <span
          aria-hidden={useTooltipId ? 'true' : undefined}
          className={`${baseClass}-text font-weight-normal ${
            props.expandedClassName ?? ''
          }`}
          id={useTooltipId}
          role={useTooltipId ? 'tooltip' : undefined}
        >
          {props.expandedText}
        </span>
      )}
    </span>
  );
};

export const ExpandingPill: React.FunctionComponent<Props> = (props: Props) => {
  const classes = classNames(
    baseClass,
    props.className,
    `mod-${props.expandMode.toLowerCase()}`
  );

  const styles = { width: props.expandedWidth ?? '60px' };
  const pillProps: PillProps = {
    size: 'small',
    ...props,
  };
  switch (props.expandMode) {
    case ExpandMode.EXPANDS:
      return (
        <div className={classes} style={styles}>
          <Pill {...pillProps} className={props.pillClass}>
            <ExpandingPillContent {...props} />
          </Pill>
        </div>
      );
    case ExpandMode.ALWAYS_COLLAPSED:
      return (
        <Pill {...pillProps} className={`${classes} ${props.pillClass}`}>
          <ExpandingPillContent {...props} expandedText="" />
        </Pill>
      );
    case ExpandMode.ALWAYS_EXPANDED:
      return (
        <Pill {...pillProps} className={`${classes} ${props.pillClass}`}>
          <ExpandingPillContent {...props} />
        </Pill>
      );
  }
};

ExpandingPill.displayName = 'ExpandingPill';
